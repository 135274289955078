import React,  { useContext, useEffect, useState, useRef } from 'react';
import { getKeycloakAccount, updateKeycloakAccount, configureSmsOtp, getPasswordPolicies, getUser, updateUser } from "../behaviors/RfcAjaxBehavior";
import { localize, localizeFormat } from "../behaviors/LocalizeBehavior";
import { AuthTokenContext } from '../behaviors/RfcContext';
import { TodoContext, ToastMessageContext, UserProfileContextSetters } from 'cnc-component-library';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import Box from '@material-ui/core/Box';
import PersonOutline from '@material-ui/icons/PersonOutline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ErrorIcon from '@material-ui/icons/Error';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PhoneIcon from '@material-ui/icons/Phone';
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  userProfileContainer: {
    "& h2": {
      margin: '5px 0px'
    },
    "& h3": {
      margin: '5px 0px'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  personalOutlineIcon: {
    height: '55px',
    width: '55px'
  },
  passPolicyContainer: {
    textAlign: 'left',
    margin: '25px 0px'
  },
  passwordPolicyHeader: {
    marginTop: '2px',
    marginLeft: '13px',
    fontSize: '13px'
  },
  buttonContainer: {
    textAlign:'center'
  },
  saveButton: {
    fontSize: '12px',
    backgroundColor: '#43A746',
    color: '#FFFFFF',
    margin: '10px 0px',
    "&:hover": {
      backgroundColor: "#43A746"
    }
  },
  configureButton: {
    fontSize: '12px',
    backgroundColor: '#2196F4',
    color: '#FFFFFF',
    margin: '10px 0px',
    "&:hover": {
      backgroundColor: "#2196F4"
    }
  },
  displayName: {
    fontSize: '1.75em',
    fontWeight: 'bold'
  },
  email: {
    fontWeight: 600
  }
}));

const initialState = {
  firstName: '',
  lastName: '',
  contactInfo: {
    phone: '',
    mobilePhone: ''
  }
}

const UserProfile = () => {
  const classes = useStyles();
  const state = useRef(useContext(TodoContext));
  const toastMessage = useContext(ToastMessageContext);
  let authToken = useContext(AuthTokenContext);
  const userProfile = useContext(UserProfileContextSetters)

  const [kcUser, setKcUser] = useState(null)
  const [user, setUser] = useState(initialState)
  const [displayName, setDisplayName] = useState("");
  const [passwords, setPasswords] = useState({
    currentPassword: null,
    newPassword: null,
    confirmPassword: null
  });
  const [showPasswords, setShowPasswords] = useState({
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false
  });
  const [focusedPass, setFocusedPass] = useState({
    focusedExisting: false,
    focusedNew: false,
    focusedConfirm: false
  });
  const [passwordPolicies, setPasswordPolicies] = useState([])

  useEffect(() => {
    let mounted = true

    getKeycloakAccount(authToken, kcUser => {        
      if (mounted) {
        getUser(authToken, user => {
          setKcUser(kcUser);
          const phone = _.get(user, 'contactInfo.phone', '')
          const userData = {
            ...user,
            contactInfo: {
              ...user.contactInfo,
              phone: formatPhone(phone),
              mobilePhone: _.get(user, 'contactInfo.mobilePhone', '')
            }
          }
          setUser(userData)
          userProfile.setUserProfile(userData)
        })
        updateDisplayName(kcUser);
      }
    });

    getPasswordPolicies(authToken, pwdPolicies => {    
      if (mounted) setPasswordPolicies(Object.entries(pwdPolicies));
    });
    
    return () => {
      mounted = false
    }
  }, [authToken, userProfile])

  const handleSave = (event) => {
    if (event) event.preventDefault();

    kcUser['credentials'] = [
        {type: 'password', value: passwords.currentPassword},
        {type: 'password-new', value: passwords.newPassword},
        {type: 'password-confirm', value: passwords.confirmPassword}
    ]

    updateKeycloakAccount(authToken, kcUser, (response) => {
      state.current.setTodoLoading(true)
      if (response.status === 400) {
        toastMessage.setToastError(response);
        state.current.setTodoLoading(false);
      } else {
        updateUser(authToken, user.id, user, (response) => {
          if (response.status === 400) {
            toastMessage.setToastError(response);
          } else {
            setPasswords({
              currentPassword: '',
              newPassword: '',
              confirmPassword: ''
            })
            updateDisplayName(user);
            userProfile.setUserProfile(user)
            toastMessage.setToastSuccess(localize('account.update.success'));
          }
          state.current.setTodoLoading(false);
        })
      }
    })
  };

  const handleConfigureSmsOtp = (event) => {
    if (event) event.preventDefault();

    configureSmsOtp(authToken, kcUser, () => {
      toastMessage.setToastSuccess(localize('email.otp.success'));
    })
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const updateDisplayName = (kcUser) => {
    setDisplayName([kcUser.firstName, kcUser.lastName].join(' '));
  }

  const handleContactInfo = e  => {
    setUser(prevState => ({
      ...prevState,
      contactInfo: { ...prevState.contactInfo, [e.target.name]: e.target.value }
    }))
  }

  const handleUserInfo = e => {
    setUser(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value 
    }))
  }

  const formatPhone = (value) => {
    let formatted = value
    if (formatted && formatted.indexOf('-') === -1 && (formatted.length === 7 || formatted.length === 10 || formatted.length === 11) ) {
      switch (formatted.length) {
        case 7: 
          formatted = [formatted.slice(0, 3), formatted.slice(3)].join('-');
          break
        
        case 10: 
          formatted = [formatted.slice(0, 3), formatted.slice(3, 6), formatted.slice(6)].join('-');
          break
        
        case 11: 
          formatted = [formatted.slice(0, 1), formatted.slice(1, 4), formatted.slice(4, 7), formatted.slice(7)].join('-');
          break
        default:
          break
      }
    }

    return formatted
  }

  if (kcUser === null) {
    return null;
  }

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs" className={classes.userProfileContainer}>
        <div className={classes.paper}>
          <PersonOutline className={classes.personalOutlineIcon} />
          <Typography variant="h6" className={classes.displayName}>
            {displayName}
          </Typography>
          <Typography variant="h6" className={classes.email}>
            {kcUser.email}
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              margin="normal"
              fullWidth
              name="firstName"
              label="First Name"
              type="text"
              id="firstname"
              value={user && user.firstName}
              onChange={handleUserInfo}
              data-testid="firstName"
            />
            <TextField
              margin="normal"
              fullWidth
              name="lastName"
              label="Last Name"
              type="text"
              id="lastname"
              value={user && user.lastName}
              onChange={handleUserInfo}
              data-testid="lastName"
            />
            <TextField
              margin="normal"
              fullWidth
              name="mobilePhone"
              label="Cell Phone"
              type="text"
              id="mobilePhone"
              value={user && user.contactInfo && user.contactInfo.mobilePhone}
              onChange={handleContactInfo}
              inputProps={{"data-testid": "mobilePhone"}}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton>
                    <PhoneIphoneIcon />
                  </IconButton>
                </InputAdornment>
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              name="phone"
              label="Phone"
              type="text"
              id="phone"
              value={user && user.contactInfo && user.contactInfo.phone}
              onChange={handleContactInfo}
              onBlur={(event) => {
                setUser({ ...user, contactInfo: { ...user.contactInfo, phone: formatPhone(event.target.value) }});
              }}
              inputProps={{"data-testid": "phone"}}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton>
                    <PhoneIcon />
                  </IconButton>
                </InputAdornment>
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              name="existingPassword"
              label="Existing Password"
              type={showPasswords.showCurrentPassword ? 'text' : 'password'}
              id="existingPassword"
              value={passwords && passwords.currentPassword ? passwords.currentPassword : ''}
              InputLabelProps={{ shrink: !!passwords.currentPassword }}
              inputProps={{
                "data-testid": "existingPassword"
              }}
              InputProps={{
                readOnly: !focusedPass.focusedExisting,
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPasswords({ ...showPasswords, showCurrentPassword: !showPasswords.showCurrentPassword })}
                    onMouseDown={handleMouseDownPassword}
                  >
                    { showPasswords.showCurrentPassword ? <Visibility /> : <VisibilityOff /> }
                  </IconButton>
                </InputAdornment>
              }}
              onChange={(event) => {
                setPasswords({ ...passwords, currentPassword: event.target.value });
              }}
              onFocus={() => {
                setFocusedPass({ ...focusedPass, focusedExisting: true });
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              name="newPassword"
              label="New Password"
              type={showPasswords.showNewPassword ? 'text' : 'password'}
              id="newPassword"
              value={passwords && passwords.newPassword ? passwords.newPassword : ''}
              InputLabelProps={{ shrink: !!passwords.newPassword }}
              inputProps={{"data-testid": "newPassword"}}
              InputProps={{
                readOnly: !focusedPass.focusedNew,
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPasswords({ ...showPasswords, showNewPassword: !showPasswords.showNewPassword })}
                    onMouseDown={handleMouseDownPassword}
                  >
                    { showPasswords.showNewPassword ? <Visibility /> : <VisibilityOff /> }
                  </IconButton>
                </InputAdornment>
              }}
              onChange={(event) => {
                setPasswords({ ...passwords, newPassword: event.target.value });
              }}
              onFocus={() => {
                setFocusedPass({ ...focusedPass, focusedNew: true });
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              name="confirmPassword"
              label="Confirm New Password"
              type={showPasswords.showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              value={passwords && passwords.confirmPassword ? passwords.confirmPassword : ''}
              InputLabelProps={{ shrink: !!passwords.confirmPassword }}
              inputProps={{"data-testid": "confirmPassword"}}
              InputProps={{
                readOnly: !focusedPass.focusedConfirm,
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPasswords({ ...showPasswords, showConfirmPassword: !showPasswords.showConfirmPassword })}
                    onMouseDown={handleMouseDownPassword}
                  >
                    { showPasswords.showConfirmPassword ? <Visibility /> : <VisibilityOff /> }
                  </IconButton>
                </InputAdornment>
              }}
              onChange={(event) => {
                setPasswords({ ...passwords, confirmPassword: event.target.value });
              }}
              onFocus={() => {
                setFocusedPass({ ...focusedPass, focusedConfirm: true });
              }}
            />

            <Box className={classes.passPolicyContainer}>
              <Typography style={{display: "flex"}}>
                <ErrorIcon fontSize="medium" style={{fill: "#4285f4"}} />
                <strong className={classes.passwordPolicyHeader}>{ localize('passwordPolicy') }</strong>
              </Typography>
              <ul>
                {
                  passwordPolicies.map((p,i) => {
                    return <li key={i}>{ localizeFormat(p) }</li>
                  })
                }
              </ul>
            </Box>
            
            <Box className={classes.buttonContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.saveButton}
                onClick={handleSave}
                data-testid="save"
              >
                { localize('save').toUpperCase() }
              </Button>
            </Box>
            <Box className={classes.buttonContainer}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.configureButton}
                onClick={handleConfigureSmsOtp}
                data-testid="submit"
              >
                { localize('configureSmsOtp').toUpperCase() }
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default UserProfile;